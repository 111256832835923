import RepeatIcon from "@mui/icons-material/Repeat";
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from "@mui/material";

import Pagination from "@mui/material/Pagination";
import moment from "moment";
import React, { useEffect, useState } from "react";
import useAxiosHook from "../../hooks/AxiosHook";
import useOrderHook from "../../hooks/OrderHook";
import { Metas, OrderInfo, OrderListType } from "../../types/orders-types";
import ConfirmationModal from "../ConfirmationModal";
import AddNote from "./AddNote";
import NoteModal from "./NoteModal";
import SaveNotes from "./SaveNotes";

function OrderList({ filled }: { filled: boolean }) {
    const { service } = useAxiosHook();

    const [rows, setRows] = useState<OrderListType[]>([]);

    const {
        getInProgressOrders,
        updateOrderShippingTime,
        orderStatus,
        updateVendorOrderNumber,
    } = useOrderHook();

    const [loading, setLoading] = useState<boolean>(false);
    const [modal, setModal] = useState<boolean>(false);
    const [orderId, setOrderId] = useState<string | number | undefined>();
    const [saveModal, setSaveModal] = useState<boolean>(false);
    const [voEdit, setVoEdit] = useState<number>(-1);
    const [page, setPage] = useState<number>(1);
    const [meta, setMeta] = useState<Metas>({
        count: 1,
        currentPage: 1,
        totalPage: 1,
    });

    const estimatedShippingDateOptions = [
        "Send Ship Docs",
        "Ready",
        "Tomorrow",
        "2-3 Days",
        "1 Week",
        "2 Weeks",
        "3 Weeks",
        "4 Weeks",
        "5+ Weeks",
        "Backordered",
        "Need Docs",
        "ETA",
        "DO NOT SHIP",
        "Rush Order",
        "Shipped",
        "Producing",
        "Release Now"
    ];

    function changeEstimatedShippingDate(
        shippingTime: string,
        orderId: number
    ) {
        updateOrderShippingTime(orderId, shippingTime).then(() =>
            fetchOrders()
        );
        return true;
    }

    async function saveVendorOrderNumber(
        vendorOrderNumber: string,
        orderId: number
    ) {
        await updateVendorOrderNumber(orderId, vendorOrderNumber);
        await fetchOrders();
        setVoEdit(-1);
    }

    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [orderInfo, setOrderInfo] = useState<OrderInfo>({
        orderId: null,
        vendorId: null,
    });

    function handleStatusChange(
        { target }: any,
        { orderId, vendorOrderNumber }: any
    ) {
        if (target?.value === "Ready") {
            if (
                !window.confirm(
                    "Are you 100% sure this order is ready to ship " +
                    (vendorOrderNumber || "") +
                    "?"
                )
            ) {
                return false;
            }
        }

        if (target?.value === "Send Ship Docs") {
            handleSendShippingDocsEvent(target?.value, orderId, vendorOrderNumber);
            return;
        }

        changeEstimatedShippingDate(target?.value, orderId);
    }

    function handleSendShippingDocsEvent(action: string, orderId: number, vendorId: number | null) {
        setShowConfirmation(true);
        setOrderInfo({
            orderId: orderId,
            vendorId: vendorId,
        });
    }

    function handleSendShippingDocsProcess() {
        setShowConfirmation(false);
        changeEstimatedShippingDate("Send Ship Docs", orderInfo.orderId as number);
    }

    async function handleProcessReady() {
        return updateOrderShippingTime(
            orderInfo.orderId as number,
            "Ready"
        ).then(() => {
            fetchOrders();
            setShowConfirmation(false);
            return true;
        });
    }

    async function fetchOrders(currentPage: number = 1) {
        setLoading(true);
        return getInProgressOrders(filled, currentPage || page)
            .then((res: any) => {
                setLoading(false);
                setMeta(res.meta);
                setRows(res.items);
            })
            .finally(() => setLoading(false));
    }

    function rowColor(row: OrderListType) {
        if (row.estimatedShippingTime === "Send Ship Docs") {
            return "#f7bcee";
        }

        if (row.estimatedShippingTime === "Producing") {
            return "#91c7ff";
        }

        if (row.estimatedShippingTime === "DO NOT SHIP") {
            return "#ebebeb";
        }

        if (row.estimatedShippingTime === "ETA") {
            return "#fe8787";
        }
        
        if (row.estimatedShippingTime === "Release Now") {
            // Purple 
            return "rgb(191 147 253)";
        }

        if (row.estimatedShippingTime === "Rush Order") {
            // Orange
            return "rgb(255 191 147)";
        }

        if (
            row.estimatedShippingTime === "Need Docs" ||
            row.estimatedShippingTime === "Tomorrow"
        ) {
            return "rgb(147 197 253)"; // Blue
        }

        if (row.status === 1) {
            return "rgb(254 240 138)"; // yellow
        }


        if (row.status === 2) {
            return "rgb(187 247 208)"; // Green
        }
    }

    function handlePagination(
        event: React.ChangeEvent<unknown>,
        value: number
    ) {
        setPage(value);
        fetchOrders(value);
    }

    // When component mounted
    useEffect(() => {
        fetchOrders();
    }, []);

    return (
        <>
            <ConfirmationModal
                open={showConfirmation}
                handleClose={() => setShowConfirmation(false)}
                orderInfo={orderInfo}
                onFetchOrders={fetchOrders}
                onProcessReady={handleSendShippingDocsProcess}
            />

            {loading && (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                </Box>
            )}
            {!loading && (
                <>
                    {rows && rows.length < 1 && (
                        <Alert severity="warning">
                            There is no order available!
                        </Alert>
                    )}
                    {rows && rows.length > 0 && (
                        <>
                            <TableContainer component={Paper}>
                                <Table
                                    sx={{ minWidth: 650 }}
                                    aria-label="simple table"
                                >
                                    <TableHead className="">
                                        <TableRow>
                                            <TableCell>Date</TableCell>
                                            <TableCell align="left">
                                                Order ID
                                            </TableCell>
                                            <TableCell align="left">
                                                BWD PO#
                                            </TableCell>
                                            <TableCell>Origin</TableCell>
                                            <TableCell align="left">
                                                Dest
                                            </TableCell>
                                            <TableCell align="left">
                                                Status
                                            </TableCell>
                                            <TableCell align="left">
                                                Customer
                                            </TableCell>

                                            {filled && (
                                                <TableCell align="left">
                                                    Total Mail
                                                </TableCell>
                                            )}

                                            <TableCell align="left">
                                                Product
                                            </TableCell>
                                            <TableCell align="left">
                                                Qty.
                                            </TableCell>
                                            <TableCell align="left">
                                                Pallets
                                            </TableCell>
                                            <TableCell align="left">
                                                Status
                                            </TableCell>
                                            <TableCell align="left">
                                                User
                                            </TableCell>
                                            <TableCell align="left">
                                                Vendor Order #
                                            </TableCell>

                                            <TableCell align="left">
                                                Notes
                                            </TableCell>
                                            {filled && (
                                                <TableCell align="left">
                                                    Action
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row, rowIndex: number) => (
                                            <TableRow
                                                key={row.id}
                                                sx={{
                                                    color: "#ffffff",
                                                    backgroundColor:
                                                        rowColor(row),
                                                    "&:last-child td, &:last-child th":
                                                        { border: 0 },
                                                }}
                                                className="myTable"
                                            >
                                                <TableCell>
                                                    {moment(
                                                        row.createdAt
                                                    ).format("M/D")}
                                                </TableCell>

                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {row.orderId}
                                                </TableCell>

                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {row.purchaseOrderNumber ||
                                                        "N/A"}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row.origin}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row.destination}
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Box sx={{ minWidth: 120 }}>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={
                                                                row.estimatedShippingTime ||
                                                                ""
                                                            }
                                                            label="ESD"
                                                            onChange={(e) =>
                                                                handleStatusChange(
                                                                    e,
                                                                    row
                                                                )
                                                            }
                                                            sx={{
                                                                height: "40px",
                                                                width: "100%",
                                                            }}
                                                            placeholder="ESD"
                                                        >
                                                            {estimatedShippingDateOptions.map(
                                                                (
                                                                    time: string,
                                                                    key: number
                                                                ) => (
                                                                    <MenuItem
                                                                        key={
                                                                            key
                                                                        }
                                                                        value={
                                                                            time
                                                                        }
                                                                    >
                                                                        {" "}
                                                                        {time}
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Select>
                                                    </Box>
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row.customer}
                                                </TableCell>

                                                {filled && (
                                                    <TableCell align="left">
                                                        {row.mailCount}
                                                    </TableCell>
                                                )}

                                                <TableCell align="left">
                                                    {row.products.map(
                                                        (
                                                            product,
                                                            key: number
                                                        ) => (
                                                            <div
                                                                key={`product-reference-${product.reference}`}
                                                                className={
                                                                    key !==
                                                                        row.products
                                                                            .length -
                                                                        1
                                                                        ? "mb-2"
                                                                        : ""
                                                                }
                                                            >
                                                                {
                                                                    product.reference
                                                                }
                                                            </div>
                                                        )
                                                    )}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row.products.map(
                                                        (
                                                            product,
                                                            key: number
                                                        ) => (
                                                            <div
                                                                key={`product-quantity-${product.quantity}${key}${product.reference}`}
                                                                className="mb-2"
                                                            >
                                                                {
                                                                    product.quantity
                                                                }
                                                            </div>
                                                        )
                                                    )}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row.pallet}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {orderStatus(row.status)}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row?.user?.name || ""}
                                                </TableCell>
                                                <TableCell
                                                    sx={{
                                                        width:
                                                            voEdit === rowIndex
                                                                ? "200px"
                                                                : "auto",
                                                    }}
                                                    align="left"
                                                >
                                                    {voEdit !== rowIndex ? (
                                                        <div
                                                            className="myLink"
                                                            onClick={() =>
                                                                setVoEdit(
                                                                    rowIndex
                                                                )
                                                            }
                                                        >
                                                            {row.vendorOrderNumber ||
                                                                "Add"}
                                                        </div>
                                                    ) : (
                                                        <TextField
                                                            id="outlined-basic"
                                                            placeholder="Vendor Order #"
                                                            variant="outlined"
                                                            margin="dense"
                                                            autoFocus={true}
                                                            helperText="Press Enter to Save"
                                                            onKeyDown={({
                                                                key,
                                                                target,
                                                            }: any) => {
                                                                if (
                                                                    key ===
                                                                    "Enter"
                                                                ) {
                                                                    saveVendorOrderNumber(
                                                                        target?.value,
                                                                        row.orderId
                                                                    );
                                                                    return;
                                                                }

                                                                if (
                                                                    key ===
                                                                    "Escape"
                                                                ) {
                                                                    setVoEdit(
                                                                        -1
                                                                    );
                                                                    return;
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                </TableCell>

                                                <TableCell align="left">
                                                    <AddNote
                                                        notesCount={
                                                            row.notesCount
                                                        }
                                                        openModal={() => {
                                                            setOrderId(row.id);
                                                            setModal(true);
                                                        }}
                                                        onOpenSavedNotesModal={() => {
                                                            setSaveModal(true);
                                                            setOrderId(row.id);
                                                        }}
                                                    />
                                                </TableCell>

                                                {filled && (
                                                    <TableCell align="left">
                                                        <Button
                                                            onClick={() =>
                                                                handleSendShippingDocsEvent(
                                                                    "Ready",
                                                                    row.orderId,
                                                                    row.vendorOrderNumber
                                                                )
                                                            }
                                                            variant="outlined"
                                                            color="inherit"
                                                            endIcon={
                                                                <RepeatIcon />
                                                            }
                                                        >
                                                            Resend Mail
                                                        </Button>
                                                    </TableCell>
                                                )}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <div className="flex justify-center mt-8 mb-8">
                                <Pagination
                                    count={meta.totalPage}
                                    onChange={handlePagination}
                                    defaultPage={page}
                                />
                            </div>
                            <SaveNotes
                                openSaveModal={saveModal}
                                closeSaveModal={() => {
                                    setSaveModal(false);
                                }}
                                orderId={orderId}
                            />
                            <NoteModal
                                isOpen={modal}
                                onClose={() => {
                                    setModal(false);
                                }}
                                orderId={orderId}
                                onCreated={() => fetchOrders()}
                            />
                        </>
                    )}
                </>
            )}
        </>
    );
}

export default OrderList;
